<template>
  <main>
    <package-header title="Edit Application" subTitle="12764 Smith"></package-header>

    <div class="container-fluid">
      <div class="row">
        <div class="col">

          <div class="mb-3 border-bottom pb-3">
            <!-- Back to applications link -->
            <router-link class="mr-2" to="/pages/applications">
              <svg class="icon icon-arrow_cta_back mr-sm-1"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta_back"></use></svg>
              <span class="d-none d-sm-inline-block">Return to Applications</span>
              <span class="d-sm-none">Back</span>
            </router-link>

            <!-- Change instrument dropdown -->
            <a href="javascript:void(0)" class="btn btn-md btn-secondary dropdown-toggle d-sm-inline-block" id="instrument" data-toggle="dropdown" aria-controls="instrument-contents" aria-expanded="false">
              Go To Application
              <span class="caret"></span>
            </a>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" href="javascript:void(0)">01 - Transfer Caso <span class="badge badge-success">E-signed</span></a>
              <a class="dropdown-item font-weight-bold" href="javascript:void(0)">02 - A1 release</a>
              <a class="dropdown-item" href="javascript:void(0)">03 - Mortgage Caso</a>
              <a class="dropdown-item" href="javascript:void(0)">04 - A2 release</a>
              <a class="dropdown-item" href="javascript:void(0)">05 - Application with longer name</a>
            </div>
          </div>

          <!-- Package title and actions -->
          <div class="d-md-flex align-items-start justify-content-between">
            <div>
              <h2 class="page-title">A1 release</h2>

              <div class="d-flex align-items-center">
                <small>
                  <span class="text-muted text-small">Application Type: </span>
                  <strong>Application Type</strong>
                </small>
                <button class="btn btn-secondary btn-sm ml-2">Change</button>
              </div>

              <ul class="list-inline mb-1">
                <li class="list-inline-item">
                  <small><span class="text-muted text-small">Status:</span> <strong>Drafted</strong></small>
                </li>
                <li class="list-inline-item">
                  <small><span class="text-muted">Modified:</span> <strong>Sep 22, 2018 at 5:33 PM</strong></small>
                </li>
              </ul>
            </div>

            <!-- Drawer toggle and delete package -->
            <div class="d-flex justify-content-between">
              <button class="btn btn-md btn-secondary d-md-none mb-2" type="button" data-toggle="collapse" data-target="#application-nav" aria-expanded="false" aria-controls="application-nav">
                <svg class="icon icon-more"><use xlink:href="/icons/symbol-defs.svg#icon-more"></use></svg>
                <svg class="icon icon-close"><use xlink:href="/icons/symbol-defs.svg#icon-close"></use></svg>
                Navigate Application
              </button>
              <button type="button" class="btn btn-md btn-danger mb-2">
                <svg class="icon icon-delete d-sm-none"><use xlink:href="/icons/symbol-defs.svg#icon-delete"></use></svg>
                <span class="d-none d-sm-block">Delete Application</span>
              </button>
            </div>
          </div>

          <!-- Main package content -->
          <div class="row mt-md-3">

            <!-- Step wizard -->
            <div class="col-md-3 mb-2 mb-md-4">
              <div class="collapse collapse-md" id="application-nav">
                <div class="step-wizard">
                  <div class="step completed">
                    <router-link class="step-link" to="/pages/edit-application/applicant">Applicant</router-link>
                  </div>
                  <div class="step active">
                    <router-link class="step-link" to="/pages/edit-application/charges-liens-interests">Charge, Lien or Interest</router-link>
                  </div>
                  <div class="step">
                    <router-link class="step-link" to="/pages/edit-application/pid-legal-description">Description of Land</router-link>
                  </div>
                  <div class="step">
                    <router-link class="step-link" to="/pages/edit-application/transferor">Transferor</router-link>
                  </div>
                  <div class="step">
                    <router-link class="step-link" to="/pages/edit-application/execution">Execution</router-link>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Attachment for Supporting Evidence</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Validation Summary</a>
                  </div>
                  <div class="step">
                    <router-link class="step-link" to="/pages/electronic-instrument">View Electronic Instrument</router-link>
                  </div>
                </div>
              </div>
            </div>

            <!-- Data entry form -->
            <div class="col-md-9">

              <h2 class="mb-3">Charge, Liens or Interests</h2>

              <div class="mb-4 p-2 p-md-4 bg-medium">

                <form action="#" class="mb-3" v-if="filingAdded == false || additionalFilingAdded == true">
                  <div class="form-group">
                    <label for="">Enter the filing number of the charge, lien, or interest for release.</label>
                    <input class="form-control w-50" type="text" v-model="filingNumber">
                  </div>
                  <button class="btn btn-md btn-tertiary" @click.prevent="addRow(filingNumber, 'EASEMENT', 'Western Canada Trust')">Add</button>
                  <button class="btn btn-md btn-link" @click.prevent="additionalFilingAdded = false" v-if="tableItems.length > 0">Cancel</button>
                </form>

                <div v-if="filingAdded == true">
                  <div class="d-flex flex-column flex-md-row-reverse justify-content-between align-items-md-center mb-1" v-if="additionalFilingAdded == false">
                    <div class="mb-2 mb-md-0">
                      <button class="btn btn-tertiary btn-md" @click="additionalFilingAdded = true">+ Add New</button>
                    </div>
                    <small>
                      Select charges, liens, or interests
                      <a class="text-tertiary collapse-toggle" href="#table-help" data-toggle="collapse" aria-expanded="false" aria-controls="table-help">
                        <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
                        <svg class="icon icon-close-circle"><use xlink:href="/icons/symbol-defs.svg#icon-close-circle"></use></svg>
                      </a>
                      <em v-if="itemSelected == true">({{ checkedNum }}/{{ rowNum }} selected)</em>
                    </small>
                  </div>

                  <!-- Help text -->
                  <div class="collapse" id="table-help">
                    <div class="card card-body bg-light pt-2 mb-3">
                      <strong>Table help text</strong>
                    </div>
                  </div>

                  <!-- Charges Liens Interests table -->
                  <table class="table table-stacked table-select-row bg-white">
                    <thead>
                      <tr>
                        <th scope="row"></th>
                        <th scope="col">Number</th>
                        <th scope="col">Type</th>
                        <th scope="col">Owner</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- Instead of the template element below, Angular should be able to use ng-repeat-start and ng-repeat-end for having two tr elements inside a loop -->
                      <template v-for="(item, index) in tableItems">
                        <tr class="border-bottom-md-0">
                          <td scope="row" rowspan="2" style="width: 1rem">
                            <div class="custom-control custom-checkbox">
                              <input :id="index" type="checkbox" class="custom-control-input" v-model="item.selected" @click="checkbox(index)">
                              <label class="custom-control-label" :for="index">&nbsp;
                              </label>
                            </div>
                          </td>
                          <td data-header="Number">
                            <span class="td-content">{{ item.a }}</span>
                          </td>
                          <td data-header="Type">
                            <span class="td-content">{{ item.b }}<br>{{ item.d }}</span>
                          </td>
                          <td data-header="Owner">
                            <span class="td-content">{{ item.c }}</span>
                          </td>
                          <td data-header="Actions">
                            <span class="td-content"><a class="text-danger" href="#" @click.prevent="remove(index)">Remove</a></span>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="4" class="border-top-0 pt-0">

                            <template v-if="item.moreInfoAdded == false">
                              <a class="d-inline-block mb-1" href="#" @click.prevent="item.moreInfo = true" v-if="item.moreInfo == false && item.selected == true"><strong>Add Information</strong> (optional)</a>
                              <a href="#" data-toggle="popover" data-placement="top" data-title="Help Text" data-content="This is an example of some help text" class="text-tertiary" data-original-title="" title="" v-if="item.moreInfo == false && item.selected == true"><svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg></a>

                              <form class="mb-1" action="" v-if="item.moreInfo == true && item.selected == true">
                                <div class="form-group">
                                  <label for="moreInformation">
                                    <strong>Additional information</strong> <i>(optional)</i>
                                    <a href="#" data-toggle="popover" data-placement="top" data-title="Help Text" data-content="This is an example of some help text" class="text-tertiary" data-original-title="" title=""><svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg></a>
                                  </label>
                                  <textarea class="form-control" name="" id="" cols="30" rows="2" v-model="item.infoText"></textarea>
                                </div>
                                <button class="btn btn-md btn-tertiary" @click.prevent="saveInfo(index)" type="submit">Save Information</button>
                                <button class="btn btn-md btn-link" @click.prevent="item.moreInfo = false">Cancel</button>
                              </form>
                            </template>

                            <div v-if="item.moreInfoAdded == true && item.selected == true">
                              <strong>Additional Information</strong>
                              <p class="mb-0">{{ item.infoText }}</p>
                              <a href="#" @click.prevent="item.moreInfoAdded = false">Edit</a>
                            </div>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="d-flex flex-column-reverse flex-sm-row justify-content-sm-between d-print-none">
                <div class="mt-2 mt-sm-0">
                  <button class="btn btn-secondary">Back</button>
                </div>
                <div class="d-flex justify-content-between">
                  <button class="btn btn-secondary mr-1">Save Progress</button>
                  <button class="btn btn-primary">Continue <svg class="icon icon-arrow_cta ml-1"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta"></use></svg></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Scroll to top -->
      <a href="#top" class="d-block float-right d-print-none scroll-to-top">
        <svg class="icon icon-arrow_upward"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_upward"></use></svg>Back to Top
      </a>
    </div>

    <!-- Diplicate filing number modal -->
    <div class="modal fade" id="duplicateFiling" tabindex="-1" role="dialog" aria-labelledby="duplicateFilingLabel" aria-hidden="false">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="duplicateFilingLabel">Add New Charge, Lien, or Interest</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"><svg class="icon icon-close"><use xlink:href="/icons/symbol-defs.svg#icon-close"></use></svg></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="d-flex">
            <svg class="icon icon-lg icon-warning text-warning mr-1"><use xlink:href="/icons/symbol-defs.svg#icon-warning"></use></svg>
            <p> Multiple items share the same filing number. Please select from the following results for "<strong>{{ filingNumber }}</strong>".</p>
            </div>

            <table class="table table-stacked table-select-row">
              <thead class="bg-light">
                <tr>
                  <th scope="row">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="all" v-model="duplicateOption1">
                      <label class="custom-control-label" for="all">&nbsp;</label>
                    </div>
                  </th>
                  <th scope="col">Number</th>
                  <th scope="col">Type</th>
                  <th scope="col">Land Title District</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="width: 1rem" scope="row">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="firstFiling" v-model="duplicateOption1">
                      <label class="custom-control-label" for="firstFiling">&nbsp;</label>
                    </div>
                  </td>
                  <td scope="col" data-header="Number">
                    <span class="td-content">{{ filingNumber }}</span>
                  </td>
                  <td scope="col" data-header="Type">
                    <span class="td-content">RIGHT OF WAY</span>
                  </td>
                  <td scope="col" data-header="Land Title District">
                    <span class="td-content">Prince Rupert</span>
                  </td>
                </tr>
                <tr>
                  <td scope="row">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="secondFiling" v-model="duplicateOption2">
                      <label class="custom-control-label" for="secondFiling">&nbsp;</label>
                    </div>
                  </td>
                  <td scope="col" data-header="Number">
                    <span class="td-content">{{ filingNumber }}</span>
                  </td>
                  <td scope="col" data-header="Type">
                    <span class="td-content">OPTION TO PURCHASE</span>
                  </td>
                  <td scope="col" data-header="Land Title District">
                    <span class="td-content">Vancouver</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-primary" @click="addRowModal(filingNumber)" data-dismiss="modal">Add</button>
          </div>
        </div>
      </div>
    </div>

    <help-panel title="Applications"></help-panel>
  </main>
</template>

<script>

var filters = {
  active: function (tableItems) {
    return tableItems.filter(function (tableItem) {
      return tableItem.selected
    })
  }
}

import jQuery from 'jquery'
import PackageHeader from '../../../components/PackageHeader.vue'
import PackageNavigation from '../../../components/PackageNavigation.vue'
import HelpPanel from '../../../components/HelpPanel.vue'

window.jQuery = jQuery
window.$ = jQuery

export default {

  components: {
    'package-header': PackageHeader,
    'package-navigation': PackageNavigation,
    'help-panel': HelpPanel
  },

  data () {
    return {
      checked: 0,
      moreInfoText: '',
      moreInformation: false,
      moreInformationAdded: false,
      filingNumber: '',
      infoText: '',
      filingAdded: false,
      additionalFilingNumber: '',
      additionalFilingAdded: false,
      duplicateOption1: false,
      duplicateOption2: false,
      tableItems: []
    }
  },

  computed: {
    rowNum: function() {
      return this.tableItems.length;
    },
    checkedNum: function() {
      return filters.active(this.tableItems).length
    }
  },

  methods: {
    addRow: function (number, type, owner) {
      var number = number && number.trim()
      var type = type && type.trim()
      var owner = owner && owner.trim()

      if (!number ) {
        return
      }

      if(this.tableItems.length === 1) {
        $('#duplicateFiling').modal();
      } else {
        this.tableItems.splice(0, 0, {
          selected: true,
          moreInfo: false,
          moreInfoText: '',
          moreInfoAdded: false,
          a: number,
          b: type,
          c: owner,
        })
        this.filingNumber = ''
        this.filingAdded = true
        this.itemSelected = true
        this.additionalFilingAdded = false
      }
    },

    addRowModal: function (number) {
      var number = number && number.trim()

      if (!number ) {
        return
      }

      if(this.duplicateOption1) {
        this.tableItems.splice(0, 0, {
          selected: true,
          moreInfo: false,
          moreInfoText: '',
          moreInfoAdded: false,
          a: number,
          b: 'RIGHT OF WAY',
          d: 'Prince Rupert'
        })
      } else if(this.duplicateOption2) {
        this.tableItems.splice(0, 0, {
          selected: true,
          moreInfo: false,
          moreInfoText: '',
          moreInfoAdded: false,
          a: number,
          b: 'OPTION TO PURCHASE',
          d: 'Vancouver'
        })
      }

      this.filingNumber = ''
      this.filingAdded = true
      this.itemSelected = true
      this.additionalFilingAdded = false
    },

    remove: function(index) {
      this.tableItems.pop(index)

      if(this.tableItems.length == 0) {
        this.filingAdded = false
      }
    },

    saveInfo: function(index) {
      this.tableItems[index].moreInfoText = this.infoText;
      this.tableItems[index].moreInfoAdded = true;
    },

    checkbox: function(index) {
      this.tableItems[index].selected = this.selected;
    }
  }
}
</script>

